/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
header,
footer,
fieldset,
figure,
blockquote,
dl,
div,
dd {
  margin: 0;
  padding: 0;
}

/* Set default font size and line height */
body {
  font-size: 16px;
  line-height: 1.5;
  max-width: 100vw;
  max-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Remove hyperlink styles */
a {
  color: inherit;
  text-decoration: none;
}

/* Remove form element styles */
button,
input,
select,
textarea {
  font-size: inherit;
  margin: 0;
}

/* Reset images to display as block elements */
img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Set a default border box for all elements */
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

/* Reset default button styles */
button {
  background-color: transparent;
  border: none;
  padding: 0;
}

/* Reset default input styles */
input {
  background-color: transparent;
  border: none;
  padding: 0;
}